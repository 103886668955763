'use client'
import { type FC, memo } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid, font72_60, font30_42 } from '@/styles'

export const SectionNotFound:FC = memo(() => {
  return (
    <Section>
      <hgroup>
        <h1>Página no encontrada</h1>
        <p>Intentalo de nuevo (copy no dinámico) PENDING </p>
      </hgroup>
    </Section>
  )
})

const Section = styled.section`
  ${designGrid({})}
  margin-top: 140px;

  ${mq.greaterThan('tablet')} {
    margin-top: 80px;
  }

  hgroup {
    grid-column:  1 / span 6;
    padding: ${vw(60, 'mobile')} 0;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(60, 'tablet')} 0;
    }

    ${mq.greaterThan('tablet')} {
      grid-column:  3 / span 8;
      padding: ${vw(80, 'desktop')} 0 ${vw(160, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 80px 0 160px;
    }
  }

  h1 {
    ${font72_60(true)}
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font72_60(false)}
      margin-bottom: ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 20px;
    }
  }

  p {
    ${font30_42(true)}
    text-align: center;

    ${mq.greaterThan('tablet')} {
      ${font30_42(false)}
    }
  }
`
